import {VueController} from '@/service/support/vuex-controller';
import vaultService from '@/service/vault-service';
import * as awsVars from '@/config/aws-exports';
let awsmobile = awsVars.default(process.env);
import moment from "moment";
import toMaterialStyle from 'material-color-hash';
import orgService from "@/service/org-service";
import {EventBus} from "@/service/support/event-bus";
import Auth from "@aws-amplify/auth";

export default VueController("VaultsByGuest", {
    data() {
        return {
            snackBar: false,
            snackBarNotify: "",
            snackBarType: "",
            logoBucket: null,
	        searchVault: ''
        };
    },
    state: [
        "vaults",
        "cognito",
	    "modals",
        "organization",
	    "organizationId",
        "user",
	    "pageIsLoading",
	    "embeddedFullScreen"
    ],
    computed: {
    	hasExternalVault() {
    		return !this.organization || this.vaults.find(vault => vault._deleted !== 1 && vault.org_id !== this.organization.id);
	    },
	    responsiveVaultHeaderLayout() {
		    switch (this.$vuetify.breakpoint.name) {
			    case 'xs':
				    return [
					    {text: "Guest Email", value: "email", sortable: true, align: 'start'},
						{text: "Portal Name", value: "name", sortable: true, align: 'start'}
				    ];
			    case 'sm':
				    return [
					    {text: "Guest Email", value: "email", sortable: true, align: 'start'},
						{text: "Guest Name", value: "userName", sortable: true, align: 'start'},
						{text: "Portal Name", value: "name", sortable: true, align: 'start'}
				    ];
			    default:
				    return [
						{text: "Guest Email", value: "email", sortable: true, align: 'start'},
						{text: "Guest Name", value: "userName", sortable: true, align: 'start'},
						{text: "Portal Name", value: "name", sortable: true, align: 'start'},
						{text: "Permissions", value: "permission", sortable: true, align: 'start'},
					    {text: "Last File Uploaded", value: "last_upload_at", sortable: true, width: "150px"}
				    ];
		    }
	    },
        filterDeletedVaults() {
            if (this.vaults) {
                return this.vaults.filter(vault => vault._deleted !== 1);
            }
        },
	    vaultsNotDeletedAndAcceptedByGuestEmail() {
    		//console.time('vault filter');
        	let nvdaa = [];
        	let self = this;

		    if (self.vaults && self.cognito) {
		    	let userId = self.cognito.attributes.sub;
		    	let userType = self.cognito.attributes["custom:mutableUserType"];

				let memberCanAccessVaults = false;
				if (self.organization) {
					let memberOrgPermissions =
						self.organization.permissions.find(perm =>
							perm._deleted !== 1 && perm.invitation_accepted === "true" && perm.user_id === userId
						);
					if (memberOrgPermissions && memberOrgPermissions.access_vault === true) {
						memberCanAccessVaults = true;
					}
				}


					nvdaa = self.vaults.filter(vault =>
						vault && vault._deleted !== 1
						&& ((self.user && userType !== "guest" && vault.org_id === self.user.memberOf && (userType === "admin" || memberCanAccessVaults)) ||
							vault.permissions && vault.permissions.some(perm =>
								perm._deleted !== 1 &&
								(perm.user_id === userId && perm.invitation_accepted === "true")
							))
					);

				//only internal vaults
				nvdaa = nvdaa.filter(vault => vault && self.organizationId && vault.org_id === self.organizationId);

				//create a new array by email
				let guestUnionPortal = [];
				for (let g = 0; g < nvdaa.length; g++) {
					let guestPerms = nvdaa[g].permissions;
					for (let p = 0; p < guestPerms.length; p++) {
						if (guestPerms[p]._deleted !== 1) {
							guestUnionPortal.push({
								email: guestPerms[p].user_email,
								userId: guestPerms[p].user_id,
								userName: guestPerms[p].user_name,
								org_id: nvdaa[g].org_id,
								vault_id: nvdaa[g].id,
								id: nvdaa[g].id,
								name: nvdaa[g].name,
								last_upload_at: nvdaa[g].last_upload_at,
								permissions: guestPerms,
								private_mode: nvdaa[g].private_mode,
								permission: guestPerms[p],
								key: guestPerms[p].user_id + "_" + nvdaa[g].id,
								orgTier: nvdaa[g].orgTier,
								num_tasks_closed: nvdaa[g].num_tasks_closed,
								num_tasks_open: nvdaa[g].num_tasks_open,
								earliest_open_task_date: nvdaa[g].earliest_open_task_date
							});
						}
					}
				}

				nvdaa = guestUnionPortal.sort((a, b) => {
					return a.email.localeCompare(b.email, 'en', { sensitivity: 'base' });
				});
		    }

		    return nvdaa;
	    }
    },
	created() {
		this.logoBucket = awsmobile.aws_org_logos_s3_bucket;
	},
    mounted() {
    	//console.time('mounted');
	    this.pageIsLoading = true;
        let self = this;

		if (self.organizationId === "none" && self.organization.id) {
			self.organizationId = self.organization.id;
		}

	    if (self.cognito.attributes["custom:mutableUserType"] === "member"
		    || self.cognito.attributes["custom:mutableUserType"] === "admin") {
		    try {
			    Tawk_API.showWidget();
		    } catch (tawkError) {
			    //console.error("Unable to load Tawk.to.");
		    }
	    }

        if (self.$route.query.embedded === "true" || self.$route.query.embedded === true) {
		    self.embeddedFullScreen = true;
	    }

        self.logoBucket = awsmobile.aws_org_logos_s3_bucket;
        self.vaults = [
            {
                name: "placeholder",
                id: 0,
                org_id: 0,
                placeholder: true
            }
        ];
		//console.time('getVaults');
        vaultService.query("getVaultsByOrgId", undefined, "vaults", {vuexCache: false})
            .then(response => {
				//console.timeEnd('getVaults');
            	let tmpOrgId = null;
				let updateVaults = false;
            	for (let r = 0; r < response.length; r++) {

            		if (!tmpOrgId && response[r].org_id) {
			            tmpOrgId = response[r].org_id;
		            } else if (response[r].org_id !== tmpOrgId) {
            			tmpOrgId = null;
            			break;
		            }
	            }
				self.pageIsLoading = false;
				//console.timeEnd('mounted');
            }).catch(error => {
            	console.error(error);
	            self.pageIsLoading = false;
            });

    },
    methods: {
		filterOnEmail(value, search, item) {
			return item.email.toLowerCase().indexOf(search.toLowerCase()) > -1;
		},
	    portalTableSort(items, index, isDesc) {
	    	let self = this;

		    items.sort((a, b) => {
		    	let sortResult = 0;
		    	if (!index || Array.isArray(index) && index.length === 0) {
				    sortResult = a.email.localeCompare(b.email, 'en', { sensitivity: 'base' });
			    } else {
				    for (let i = 0; i < index.length; i++) {
					    let subIndex = index[i];
					    let subIsDesc = isDesc[i];

					    //console.log(`Sort index ${subIndex} descending ${JSON.stringify(subIsDesc)} is ${typeof subIsDesc}`);

					    if (subIndex === "email") {
						    if (!subIsDesc) {
							    sortResult = a.email.localeCompare(b.name, 'en', { sensitivity: 'base' });
						    } else {
							    sortResult = b.email.localeCompare(a.name, 'en', { sensitivity: 'base' });
						    }
					    } else if (subIndex === "_lastChangedAt") {
						    let latestA = a.last_upload_at > a.last_download_at ? a.last_upload_at : a.last_download_at;
						    let latestB = b.last_upload_at > b.last_download_at ? b.last_upload_at : b.last_download_at;

						    if (!subIsDesc) {
							    return latestA < latestB ? -1 : 1;
						    } else {
							    return latestB < latestA ? -1 : 1;
						    }
					    } else if (subIndex) {
						    if (!subIsDesc) {
							    return a[subIndex] < b[subIndex] ? -1 : 1;
						    } else {
							    return b[subIndex] < a[subIndex] ? -1 : 1;
						    }
					    }

					    if (sortResult !== 0) {
						    break;
					    }
				    }
			    }

			    return sortResult;
		    });

		    return items;
	    },
        momentDate(timestamp, dateOnly) {
            return moment.unix(timestamp / 1000).format(dateOnly ? "MM/DD/YYYY" : "MM/DD/YYYY hh:mm a");
        },
        getVaultMemberInitials(vault) {
            let permissions = vault.permissions.filter(perm => perm._deleted !== 1);

            let initials = [];
            for (let p = 0; p < permissions.length; p++) {
                let username = permissions[p].user_name;
				let hexColor = toMaterialStyle(username);

				initials.push({
					initials: getInitials(username),
					class: {
						"background-color": permissions[p].invitation_accepted === "true" ? hexColor.backgroundColor : "lightgray",
						"color": permissions[p].invitation_accepted === "true" ? hexColor.color : "black"
					},
					username: permissions[p].invitation_accepted === "true" ? username : username + " (Pending)"
				});
            }

            return initials;
        },
        isVaultPendingInvitation(vault) {
            let vaultIsPendingInvitation = false;
            if (this.user && this.user.vaults && this.cognito && this.cognito.attributes['custom:mutableUserType'] === "guest") {
            	let foundVault = this.user.vaults.find(testVault => testVault.vault_id === vault.id && testVault.invitation_accepted === "none" && testVault._deleted !== 1);
            	if (foundVault) {
		            vaultIsPendingInvitation = true;
	            }
            }
            return vaultIsPendingInvitation;
        },
	    signOutUser() {
		    this.cache = {};
		    Auth.signOut({ global: true })
			    .then(data => {
				    EventBus.$emit('authState', {
					    status: 'signedOut'
				    });
			    })
			    .catch(err => console.log(err));
	    }
    }
});

function getInitials(value) {
    let initials = [];
    try {
	    initials = value.toUpperCase().replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g);
	    if (initials === null || initials.length === 0) {
		    initials = value.substring(0, 1).toUpperCase();
	    }
    } catch(e) {
    	console.error("Unable to get initials.");
    }

    return initials.join('');
}

function stringToColour(str) {
    var hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = '#';
    for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
}

function lightOrDark(color) {

    // Variables for red, green, blue values
    var r, g, b, hsp;

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {

        // If RGB --> store the red, green, blue values in separate variables
        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

        r = color[1];
        g = color[2];
        b = color[3];
    } else {

        // If hex --> Convert it to RGB: http://gist.github.com/983661
        color = +("0x" + color.slice(1).replace(
            color.length < 5 && /./g, '$&$&'));

        r = color >> 16;
        g = color >> 8 & 255;
        b = color & 255;
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
    );

    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 127.5) {

        return 'light';
    } else {

        return 'dark';
    }
}

const toBase64 = file => new Promise((resolve, reject) => {
	const reader = new FileReader();
	reader.readAsArrayBuffer(file);
	reader.onload = () => resolve(btoa(String.fromCharCode.apply(null, new Uint8Array(reader.result))));
	reader.onerror = error => reject(error);
});
