var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.pageIsLoading),expression:"!pageIsLoading"}],staticClass:"animated fadeIn fill fill-height px-6"},[_c('v-row',[_c('v-col',[_c('div',[(!_vm.organization)?_c('div',{staticClass:"fake-heading"}):_c('div',[_c('h1',[_vm._v(_vm._s(_vm.organization ? _vm.organization.companyName : ''))])])])]),(_vm.embeddedFullScreen)?_c('v-col',{attrs:{"cols":"2"}},[_c('v-row',{staticStyle:{"margin-top":"4px","margin-bottom":"8px"}},[_c('v-btn',{staticStyle:{"margin-left":"auto","margin-right":"20px"},attrs:{"small":""},on:{"click":_vm.signOutUser}},[_vm._v("Sign Out ")])],1)],1):_vm._e()],1),_c('v-row',{attrs:{"align":"end"}},[(_vm.organization)?_c('h3',{staticStyle:{"padding-left":"12px","margin-top":"0"}},[_vm._v("All Client Portals "),_c('span',{staticStyle:{"color":"dimgrey","font-size":"16px"}},[_vm._v("("+_vm._s(_vm.vaultsNotDeletedAndAcceptedByGuestEmail.length)+")")])]):_vm._e()]),(_vm.vaultsNotDeletedAndAcceptedByGuestEmail.length > 0)?_c('v-row',[_c('v-col',[_c('v-text-field',{staticClass:"mb-3 ml-2",staticStyle:{"max-width":"600px"},attrs:{"append-icon":"mdi-magnify","label":"Filter Guest Email","single-line":"","hide-details":""},model:{value:(_vm.searchVault),callback:function ($$v) {_vm.searchVault=$$v},expression:"searchVault"}})],1)],1):_vm._e(),(_vm.vaultsNotDeletedAndAcceptedByGuestEmail.length > 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"pt-0"},[_c('v-data-table',{attrs:{"footer-props":{ itemsPerPageOptions: [-1] },"mobile-breakpoint":0,"search":_vm.searchVault,"item-key":'key',"custom-sort":_vm.portalTableSort,"custom-filter":_vm.filterOnEmail,"headers":_vm.responsiveVaultHeaderLayout,"items":_vm.vaultsNotDeletedAndAcceptedByGuestEmail,"sort-by":"email"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticStyle:{"margin-top":"5px"}},[(item.id !== 0 && !_vm.isVaultPendingInvitation(item))?_c(!_vm.hasVaultAccess(item, _vm.cognito) ? 'span' : 'router-link',{tag:"router-link",staticStyle:{"display":"inline-flex"},attrs:{"to":("/org/" + (item.org_id) + "/vault/" + (item.id) + "/folder/main")}},[_c('h4',[_vm._v(_vm._s(item.name))]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.private_mode)?_c('v-icon',_vm._g(_vm._b({staticClass:"pa-0 ml-3",attrs:{"x-small":""}},'v-icon',attrs,false),on),[_vm._v("fa fa-lock")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Private Portal")])]),(item.num_tasks_closed || item.num_tasks_open)?_c('div',{directives:[{name:"large_firm",rawName:"v-large_firm",value:(item ? item.orgTier: ''),expression:"item ? item.orgTier: ''"}],staticClass:"ml-8",staticStyle:{"margin-top":"-6px"}},[_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(item.num_tasks_closed)+" of "+_vm._s(item.num_tasks_closed + item.num_tasks_open)+" Tasks Completed")]),_c('v-progress-linear',{attrs:{"color":item.num_tasks_open && item.num_tasks_open > 0 ? 'blue-grey lighten-1' : '#8DDD99',"height":"7","value":(item.num_tasks_closed / (item.num_tasks_closed + item.num_tasks_open)) * 100}})],1):_vm._e()],1):(_vm.isVaultPendingInvitation(item))?_c('h4',[_vm._v(_vm._s(item.name)+" (Pending Invitation)")]):_c('div',{staticClass:"fake-title"})],1)])]}},{key:"item.permission",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"border-bottom":"1px solid #eee","padding-bottom":"8px"}},[(item.permission.upload_files)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"px-1 mr-1",staticStyle:{"color":"white","opacity":".7"},attrs:{"x-small":"","color":"blue"}},'v-chip',attrs,false),on),[_vm._v("Upload ")])]}}],null,true)},[_c('span',[_vm._v("Upload Files")])]):_vm._e(),(item.permission.view_files)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"px-1 mr-1",staticStyle:{"color":"white","opacity":".7"},attrs:{"x-small":"","color":"green"}},'v-chip',attrs,false),on),[_vm._v("Download ")])]}}],null,true)},[_c('span',[_vm._v("View Files")])]):_vm._e(),(item.permission.rename_files)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"px-1 mr-1",attrs:{"x-small":""}},'v-chip',attrs,false),on),[_vm._v("Rename")]),_c('v-chip',_vm._g(_vm._b({staticClass:"px-1 mr-1",attrs:{"x-small":""}},'v-chip',attrs,false),on),[_vm._v("Move")])]}}],null,true)},[_c('span',[_vm._v("Rename and Move Files")])]):_vm._e(),(item.permission.delete_files)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"px-1 mr-1",staticStyle:{"color":"white","opacity":".7"},attrs:{"x-small":"","color":"red"}},'v-chip',attrs,false),on),[_vm._v("Delete ")])]}}],null,true)},[_c('span',[_vm._v("Delete File")])]):_vm._e()],1)]}},{key:"item.last_upload_at",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"font-size":".85em"}},[_vm._v(" "+_vm._s(_vm.momentDate(item.last_upload_at))+" ")])]}}],null,false,1789300510)})],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }